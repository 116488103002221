import React from "react";
import { concatClassNames as cn } from "@system42/core";

import Layout from "../layouts/layout";

import { HeaderTitle } from "../components/HeaderTitle";
import { HtmlContent } from "../components/HtmlContent";
import { WavySeparator } from "../components/WavySeparator";

import * as helperStyles from "./helpers.module.css";

const TesterTerms = () => (
  <Layout title={"Allgemeine Geschäftsbedingungen"}>
    <article>
      <HeaderTitle
        overline={"Zuletzt geändert: 14. Mai 2019"}
        titleWidth={"17em"}
      >
        Allgemeine Geschäftsbedingungen („Vereinbarung“)
      </HeaderTitle>

      <WavySeparator style={{ paddingTop: "var(--spacing-md)" }} />

      <div className={helperStyles.container}>
        <HtmlContent
          className={cn(helperStyles.spacingMd, helperStyles.widthHtmlContent)}
        >
          <h2>1. Annahme und Abschluss der Vereinbarung</h2>
          <p>
            Dies ist eine Vereinbarung zwischen der Userbrain GmbH
            („Userbrain“), dem Eigentümer und Betreiber von www.userbrain.com
            und den angebotenen Anwendungen, Software und Diensten von Userbrain
            (nachstehend definiert und zusammen die „Plattform“) sowie Ihnen
            („Sie“, „Ihr“ oder „Benutzer“), dem Benutzer der Plattform.
          </p>
          <p>
            In dieser Vereinbarung beziehen sich die Wörter „Userbrain“, „uns“,
            „wir“ und „unser“ auf unser Unternehmen, Userbrain, wie es im
            Zusammenhang mit der Verwendung der Wörter angemessen ist.
          </p>
          <p>
            Indem Sie sich als Userbrain Tester registrieren, auf die Plattform
            oder die Userbrain-Dienste zugreifen oder diese nutzen, stimmen Sie
            allen Bedingungen dieser Vereinbarung zu. Wir behalten uns das Recht
            vor, diese Vereinbarung und unsere Datenschutzrichtlinie jederzeit
            zu ändern. Im Falle einer Änderung werden wir sie rechtzeitig
            benachrichtigen. Wenn Sie den Nutzungsbedingungen oder der
            Datenschutzrichtlinie nicht zustimmen, beenden Sie bitte sofort die
            Nutzung der Plattform und allen Userbrain-Diensten.
          </p>

          <h2>2. Benutzerinformationen und Konten</h2>
          <p>
            Benutzer müssen sich registrieren oder anmelden, bevor sie auf die
            Plattform oder die Userbrain-Dienste zugreifen. Ihre Daten werden
            gemäß unserer Datenschutzrichtlinie erfasst und weitergegeben. Die
            Richtigkeit der auszufüllenden Angaben zur Person, insbesondere zu
            Alter und Wohnsitzstaat, sind für Userbrain und ihren Kunden von
            grundlegender Bedeutung. Alle Benutzer müssen daher bei der
            Registrierung für unsere Plattform{" "}
            <strong>wahrheitsgetreue und genaue Angaben</strong> machen, dürfen
            sich nur für jeweils ein Konto registrieren und müssen über 18 Jahre
            alt sein.
          </p>
          <p>
            Wir behalten uns das Recht vor, alle Benutzerdaten zu überprüfen und
            Benutzer abzulehnen. Wir behalten uns auch das Recht vor, eigenes
            Ermessen bei der Gewährung oder Verweigerung bezüglich der von
            Benutzern erstellten Konten auszuüben. Das Recht zur Ablehnung oder
            Löschung von Benutzerkonten besteht auch noch zu jedem späteren
            Zeitpunkt, soweit diesfalls nicht eine andere, speziellere
            Bestimmung dieser Bedingungen zur Anwendung gelangt. Sie sind allein
            dafür verantwortlich, alle Kontoinformationen auf dem neuesten Stand
            zu halten, die Vertraulichkeit Ihrer Kontoinformationen sowie
            hinsichtlich aller Aktivitäten zu wahren, die unter Ihrem Konto
            stattfinden. Sie dürfen den Zugriff bzw die Nutzung auf Ihr Konto
            nicht mit Dritten teilen. Sie erklären sich damit einverstanden,
            Userbrain unverzüglich über jede unbefugte Nutzung Ihres Kontos oder
            jede andere Sicherheitsverletzung zu benachrichtigen. Userbrain
            haftet nicht für Verluste, die Ihnen dadurch entstehen, dass jemand
            Ihr Passwort oder Ihr Konto verwendet, egal ob die Nutzung mit oder
            ohne Ihr Wissen erfolgte.
          </p>

          <h2>3. Zugang</h2>
          <p>
            Nach der Registrierung gewähren wir Ihnen, sofern erforderlich, den
            von uns genehmigten Zugang zur Plattform gemäß dieser Vereinbarung.
            Alle Rechte, die nicht explizit und ausdrücklich gegenüber Benutzern
            gewährt werden, bleiben Userbrain vorbehalten. Wenn Sie Software
            herunterladen, um auf unsere Plattform zuzugreifen, gewähren wir
            Ihnen eine begrenzte, vollständig widerrufliche, nicht exklusive
            Lizenz zum Herunterladen einer Kopie unserer Plattform auf Ihr(e)
            elektronische(n) Gerät(e), wie von uns genehmigt. Wenn Sie gegen
            diese Vereinbarung verstoßen, kann Ihr Zugang oder Ihre Lizenz zur
            Nutzung unserer Plattform nach unserem Ermessen beendet werden.
            Darüber hinaus können wir Ihren Zugang oder Ihre Lizenz zur Nutzung
            unserer Plattform widerrufen, wenn wir der Meinung sind, dass Ihre
            Handlungen uns, unseren Geschäftsinteressen oder Rechten Dritter
            schaden könnten. Wenn wir Ihren Zugang nicht widerrufen, bedeutet
            dies keine Billigung von Verfehlungen und keinen Verzicht auf die
            Geltendmachung von daraus allenfalls resultierenden Ansprüchen.
          </p>

          <h2>4. Eigentum an der Plattform</h2>
          <p>
            Der Nutzer erkennt an, dass Aufbau, Struktur, Organisation, Design
            und Code der Plattform und aller zugehörigen Softwarekomponenten
            Eigentum von Userbrain und/oder den Lizenzgebern von Userbrain sind
            und dass Userbrain und/oder seine Lizenzgeber das ausschließliche
            Eigentum an der Plattform, allen Dokumentationen, Informationen und
            allen anderen Eigentumsrechten bzw geistigen Eigentums- und
            Urheberrechten in Bezug auf die Plattform, einschließlich aller
            Modifikationen, Kopien, Erweiterungen, Derivate und anderer Software
            und Materialien, die hierunter von Userbrain entwickelt wurden, inne
            hat. Der Benutzer darf die Plattform, den Zugang zur Plattform oder
            Teile der Plattform, einschließlich aller Modifikationen,
            Verbesserungen, Derivate und anderer Software und Materialien, die
            im Rahmen dieser Vereinbarung entwickelt wurden, sowie alle
            geistigen Eigentums- und Urheberrechte nicht verkaufen, kopieren,
            reproduzieren, übertragen, veröffentlichen, offenlegen, anzeigen
            oder anderweitig verfügbar machen Userbrain an andere unter
            Verletzung dieser Vereinbarung. Der Benutzer darf keine (geistigen)
            Eigentums-, Urheberrechts-, Markenrechte aller Art und sonstige
            Rechte von irgendeinem Teil der Plattform entfernen oder verändern
            oder sonst bearbeiten, einschließlich aller Modifikationen,
            Verbesserungen, Derivate und anderer von Userbrain entwickelter
            Software und Materialien. Der Benutzer verpflichtet sich, alle
            notwendigen und angemessenen Anstrengungen zu unternehmen, um die
            obigen Bestimmungen in einer Weise einzuhalten, dass die Rechte von
            Userbrain gemäß dieser Vereinbarung gewahrt werden.
          </p>

          <h2>5. Plattform und Dienste</h2>
          <p>
            Über die Plattform kann Userbrain Ihnen Dienste zur Verfügung
            stellen, einschließlich, aber nicht beschränkt auf Dienste zur
            Durchführung von User Tests (zusammen die „Userbrain-Dienste“). Über
            die Userbrain-Dienste können Benutzer Softwareunternehmen, mobile
            Anwendungen und Websites für Userbrain-Kunden und andere Dritte
            testen und Feedback geben (jeweils ein „Test“). Bitte beachten Sie,
            dass alle Userbrain-Dienste und die Plattform „wie besehen“ und „wie
            verfügbar“ angeboten werden. Darüber hinaus werden die
            Userbrain-Dienste zusammen mit allen Informationen oder Inhalten auf
            unserer Plattform, einschließlich aller Benachrichtigungen,
            Berichte, Analysen oder bereitgestellten Tools, nur zu
            Informationszwecken angeboten. Sie stimmen zu, dass alle
            Informationen oder Benachrichtigungen von der Plattform ungenau,
            unbegründet oder möglicherweise sogar falsch sein können. Die
            Plattform und die Userbrain-Dienste stellen keine rechtliche,
            professionelle, medizinische oder medizinische Beratung oder
            Diagnose dar und dürfen nicht für solche Zwecke verwendet werden.
            Darüber hinaus haftet Userbrain den Benutzern gegenüber nicht für
            Inhalte Dritter, einschließlich, aber nicht beschränkt auf Inhalte,
            Benutzerinhalte, Inhalte Dritter, Testinhalte, Daten, Informationen,
            Kopien, Bilder, URL-Namen und alles andere, was durch Sie oder
            Dritte, die die Plattform nutzen übermittelt wird. (zusammen
            „Nicht-Userbrain-Inhalte“). Insbesondere haftet Userbrain nicht für
            Fehler oder die Genauigkeit in Bezug auf Inhalte, die nicht von
            Userbrain stammen bzw Userbrain nicht gehören. Alle Fehler liegen in
            der alleinigen Verantwortung und Haftung des Benutzers oder des
            Dritten, der solche Inhalte, die Userbrain nicht gehören bzw nicht
            von Userbrain stammen, auf der Plattform einbringt bzw dort
            zugänglich macht. Darüber hinaus haften wir nicht für Schäden oder
            sonstige Nachteile welcher Art auch immer, die aus einer
            Unterbrechung oder Störung der Plattform oder deren Beendigung
            resultieren, die direkt oder indirekt durch Umstände außerhalb
            unserer Kontrolle verursacht werden, einschließlich, aber nicht
            beschränkt auf Ursachen, die in der Verantwortung von Benutzern
            liegen, wie etwa (aber nicht abschließend): jede Art von Zufall und
            höherer Gewalt; Streik; Aufstand; Angriffe; reale oder digitale
            Anschläge und Übergriffe welcher Art auch immer; Krieg; Pandemie;
            jede Art von Naturkatastrophen wie Überschwemmung, Blitzschlag,
            Erdbeben, Wind, Eis, extreme Wetterbedingungen und jede andere Art
            von höherer Gewalt und Naturkatastrophen; jede Art von technischen
            Gebrechen; Änderungen oder Einschränkungen durch Gesetze,
            Verordnungen, Gerichtsentscheidungen, Handlungen oder Anordnungen
            einer Regierung, die den Betrieb oder den Zugriff auf die Plattform
            einschränken oder verbieten; etc.{" "}
          </p>

          <h2>6. Tests</h2>
          <p>
            Benutzer dürfen nur Tests durchführen, die von der Plattform
            genehmigt wurden bzw für den konkreten Nutzer dort zur Durchführung
            hochgeladen wurden. Die Benutzer erklären sich damit einverstanden,
            vor und während der Durchführung von Tests auf der Plattform
            wahrheitsgemäße und genaue Informationen bereitzustellen. Es ist den
            Benutzern bekannt aufgrund der auf der Homepage vorhandenen
            Informationen über Tests bzw die Tätigkeit von Testern sowie
            aufgrund der realen Durchführung sowie aufgrund dieser expliziten
            Festhaltung und Vereinbarung, dass Benutzer hinsichtlich der
            konkreten Durchführung von Tests keine Weisungen von Userbrain
            erhalten, wie sie die Tests durchzuführen zu haben bzw hierbei nicht
            kontrolliert werden oder angestellt sind. Ausgenommen davon sind
            selbstverständlich Informationen, die der Benutzer zum Verständnis
            und zur grundlegenden Möglichkeit, Test entsprechend durchzuführen,
            unbedingt benötigt. Die Art und Weise der Durchführung der Tests
            werden ausschließlich von Ihnen, einem unabhängigen Auftragnehmer,
            bestimmt und kontrolliert.{" "}
            <strong>
              Der Benutzer nimmt zustimmend zur Kenntnis, dass Userbrain keine
              Materialien, Produkte, Inhalte und ähnliches, die nicht von
              Userbrain stammen, und/oder sonst externe Inhalte oder Verhalten
              Dritter bzw Dritte, die im Rahmen der Tests auf die Plattform
              gelangen, empfiehlt oder befürwortet oder dafür haftet.
            </strong>{" "}
            Bitte beachten Sie, dass wir keine Tests zur Verfügung stellen
            müssen und das Angebot von Tests oder anderen Userbrain-Diensten
            nach eigenem Ermessen einstellen können. Einige Tests können
            spezielle Zulassungs- und Ausrüstungsanforderungen haben, die auf
            der Plattform angegeben werden. Der Benutzer ist dafür
            verantwortlich, zu überprüfen, ob er/sie alle Berechtigungs- und
            Ausrüstungsanforderungen erfüllt, die für jeden anwendbaren Test
            angegeben sind. Userbrain behält sich das Recht und den
            Ermessensspielraum vor, alle Berechtigungsvoraussetzungen für einen
            Benutzer festzulegen und jeden Benutzer von der Teilnahme an einem
            auf der Plattform angebotenen Test abzulehnen. Dies auch
            nachträglich, nachdem ein Benutzer einen Test bereits durchgeführt
            hat, wenn sich herausstellt, dass ein Benutzer tatsächlich nicht
            über die Berechtigungs- und Ausrüstungsanforderungen verfügte.
          </p>

          <h2>7. Vertraulichkeit von Tests</h2>
          <p>
            Sie stimmen zu, dass alle Tests vertraulich sind. Sie stimmen zu,
            dass Sie keine Tests, Aufzeichnungen oder damit verbundene
            Informationen und ähnliches verwenden, offenlegen oder sonst in den
            Kenntnisbereich von Dritten bringen, es sei denn, diese
            Informationen sind öffentlich bekannt, oder es sei denn, es wurde
            ausdrücklich von Userbrain genehmigt. Diese Verpflichtung gilt
            während und unbefristet auch nach Vertragsdauer. Bei einem Verstoß
            gegen die Geheimhaltungsverpflichtung verpflichtet sich der Benutzer
            zur Zahlung einer verschuldensunabhängigen und vom Vorliegen eines
            konkreten Schadens unabhängigen Konventionalstrafe in Höhe von – im
            beiderseitigen Einvernehmen pauschaliert – USD 5000. Diese
            Konventionalstrafe wird mit dem Zeitpunkt der Kenntnis des Verstoßes
            durch Userbrain zur Zahlung fällig. Darüberhinausgehende
            Schadenersatzansprüche oder sonstige Ansprüche von Userbrain oder
            Dritten bleiben davon unberührt. Die Angemessenheit dieser
            Konventionalstrafe wird ausdrücklich anerkannt und unterliegt nicht
            dem richterlichen Mäßigungsrecht. Sie erklären sich damit
            einverstanden, eine gesonderte Geheimhaltungsvereinbarung
            unterzeichnen für den Fall, dass dies von Userbrain oder einem
            Kunden von Userbrain verlangt wird.
          </p>

          <h2>8. Verantwortlichkeiten des Benutzers</h2>
          <p>
            Der Benutzer sichert hiermit ausdrücklich zu und gewährleistet,
            dass: (1) der Benutzer alle benötigten Materialien im Zusammenhang
            mit der Durchführung von Tests bereitstellt, einschließlich, aber
            nicht beschränkt auf elektronische Geräte, Netzwerkverbindungen,
            Videoaufzeichnungsgeräte und andere Geräte, die für die Durchführung
            von Tests erforderlich sind und sämtliche für die Durchführung von
            Test ansonsten allenfalls notwendigen Ausgaben selbst trägt; (2) der
            Benutzer ordnungsgemäß autorisiert ist, vollständige und richtige
            Angaben zu seiner Person tätigt und die Befugnis hat, diesen Vertrag
            abzuschließen; (3) der Benutzer durch den Abschluss dieser
            Vereinbarung mit Userbrain keine Vereinbarungen mit Dritten
            verletzt; (4) der Benutzer sich nach Treu und Glauben an diese
            Vereinbarung und alle anderen Gesetze, Regeln und Anweisungen halten
            wird, auch jene Informationen, die von Userbrain zur korrekten
            Durchführung von Tests bereitgestellt werden; (5) der Benutzer die
            Tests professionell und gewissenhaft durchführt; dazu zählt
            insbesondere, dass der Benutzer alle gestellten Aufgabenstellungen
            vollständig durchführt und die gestellten Fragen vollständig
            beantwortet, Audio- und Videoaufzeichnung störungs- und
            unterbrechungsfrei vorhanden sind, der Benutzer während des Tests
            laut seine Gedanken mitspricht und die Testaufzeichnung vollständig
            hochgeladen hat; (6) der Benutzer die Plattform nur für legale und
            rechtmäßige Zwecke nutzt.
          </p>

          <h2>9. Datenschutz des Benutzers</h2>
          <p>
            Wir schätzen Ihre Privatsphäre und verstehen Ihre
            Datenschutzbedenken. Bitte lesen Sie unsere Datenschutzrichtlinie,
            damit Sie sich über unsere Datenschutzpraktiken informieren und
            diese nachvollziehen können. Alle von uns gesammelten Informationen
            unterliegen unserer Datenschutzrichtlinie.
          </p>

          <h2>10. Nutzung der Plattform</h2>
          <p>
            Der Benutzer trägt für die gesamte Nutzung der Plattform selbst
            Verantwortung. Sie stimmen zudem folgendem zu:
          </p>
          <ul>
            <li>
              Sie dürfen keinen Teil der Plattform auf irgendeinem Medium
              kopieren, verteilen oder offenlegen, einschließlich und ohne
              Einschränkung durch automatisiertes oder nicht automatisiertes
              „Scraping“;
            </li>
            <li>
              Sie dürfen nicht versuchen, die Systemintegrität oder -sicherheit
              zu beeinträchtigen, die Systemintegrität oder -sicherheit zu
              gefährden oder Übertragungen zu oder von den Servern, auf denen
              die Plattform ausgeführt wird, zu entschlüsseln;
            </li>
            <li>
              Sie dürfen keine Roboter, Spider, Crawler, Scraper oder andere
              automatisierte Mittel oder Schnittstellen verwenden, die nicht von
              uns bereitgestellt werden, um auf die Plattform zuzugreifen oder
              Daten zu extrahieren;
            </li>
            <li>
              Sie dürfen keine automatisierten Bots oder andere Software
              verwenden, um mehr Nachrichten über unsere Plattform zu senden,
              als dies bei ordnungsgemäßer Nutzung angemessen, zweckgerichtet
              und üblich ist;
            </li>
            <li>
              Sie dürfen die Plattform nicht auf einem Computer verwenden, der
              zum Betrieb von Nuklearanlagen, Lebenserhaltung oder anderen
              geschäftskritischen Anwendungen verwendet wird, bei denen Leben
              oder Eigentum auf dem Spiel stehen können;
            </li>
            <li>
              Sie dürfen die Plattform oder Teile davon nicht dekompilieren,
              zurückentwickeln, disassemblieren, modifizieren, vermieten,
              verkaufen, leasen, verleihen, verteilen oder abgeleitete Werke
              oder Verbesserungen erstellen;
            </li>
            <li>
              Sie dürfen nicht auf unsere Plattform zugreifen, um ein ähnliches
              oder anderes Konkurrenzprodukt zu entwickeln;
            </li>
            <li>Sie dürfen die Plattform nicht rechtswidrig nutzen;</li>
            <li>
              Sie dürfen keine Maßnahmen ergreifen, die unserer Infrastruktur
              eine unangemessene oder unverhältnismäßig hohe Belastung
              auferlegen oder nach unserem alleinigen Ermessen auferlegen;
            </li>
            <li>
              Sie dürfen keine personenbezogenen Daten, einschließlich
              Kontonamen, von der Plattform sammeln, es sei denn, dies ist
              zulässig;
            </li>
            <li>
              Sie dürfen sich nicht als natürliche oder juristische Person
              ausgeben oder Ihre Zugehörigkeit zu einer natürlichen oder
              juristischen Person falsch darstellen;
            </li>
            <li>
              Sie dürfen das geistige Eigentum, die Privatsphäre oder andere
              vertragliche Rechte anderer Personen während der Nutzung unserer
              Plattform nicht verletzen oder verletzen;
            </li>
            <li>
              Sie dürfen keine Anforderungen, Verfahren, Richtlinien oder
              Vorschriften von mit Userbrain verbundenen Netzwerken verletzen;
            </li>
            <li>
              Sie dürfen die Plattform oder den Zugriff darauf nicht verkaufen,
              leasen, verleihen, verteilen, übertragen oder unterlizenzieren
              oder Einnahmen aus der Nutzung oder Bereitstellung der Plattform
              erzielen, es sei denn, dies wird durch die Funktionalität unserer
              Plattform ermöglicht;
            </li>
            <li>Sie dürfen die Plattform nicht behindern oder stören;</li>
            <li>
              Sie dürfen keine österreichischen Gesetze oder Vorschriften
              verletzen und sind für solche Verstöße allein verantwortlich;
            </li>
            <li>
              Sie erklären sich damit einverstanden, die Plattform in keiner
              Weise zu nutzen, die irreführend, rechtswidrig, diffamierend,
              obszön, bedrohlich, herabsetzend oder belästigend ist.
            </li>
            <li>
              Sie erklären sich damit einverstanden, dass Sie Userbrain nicht
              für Ihre Nutzung unserer Plattform verantwortlich machen; und
            </li>
            <li>
              Sie erklären sich damit einverstanden, die Störung, Zerstörung,
              Manipulation, Entfernung, Deaktivierung oder Beeinträchtigung
              eines Teils unserer Plattform nicht zu verursachen oder zu
              unterstützen, einschließlich der Deindexierung oder De-Caching
              eines Teils unserer Plattform von einer Website Dritter aus
              beispielsweise indem Sie die Entfernung aus einer Suchmaschine
              beantragen.
            </li>
          </ul>
          <p>
            Wenn festgestellt wird, dass Sie Handlungen entsprechend eines oben
            genannten Punktes durchführen, können Ihre Rechte zur Nutzung
            unserer Plattform nach unserem Ermessen beendet oder ausgesetzt
            werden. Wenn wir glauben, dass Ihre Handlungen uns oder Dritten
            schaden könnten, können wir Ihre Nutzung der Plattform aussetzen
            oder beenden. Grundsätzlich geben wir eine Erklärung ab, wenn wir
            die Aussetzung oder Beendigung Ihrer Nutzung verfügen. Wir behalten
            uns aber das Recht vor, jedes Konto jederzeit ohne Vorankündigung
            oder Erklärung zu sperren oder zu kündigen.
          </p>

          <h2>11. Benutzerinhalte</h2>
          <p>
            Die Möglichkeit des Benutzers, Informationen über die Plattform zu
            übermitteln oder zu übertragen, einschließlich, aber nicht
            beschränkt auf Daten, Bewertungen, Videoinhalte, schriftliche
            Inhalte, Bilder oder andere Informationen, wird in dieser
            Vereinbarung als „Benutzerinhalte“ bezeichnet. Bitte beachten Sie,
            dass wir nicht verpflichtet sind, Ihre Benutzerinhalte zu hosten,
            anzunehmen, anzuzeigen, zu migrieren oder weiterzuleiten bzw zu
            verbreiten; wir können die Annahme oder Übertragung von
            Benutzerinhalten ablehnen. Sie erklären sich damit einverstanden,
            dass Sie allein für alle übermittelten Benutzerinhalte
            verantwortlich sind und uns von jeglicher Haftung im Zusammenhang
            mit übermittelten Benutzerinhalten entbinden. Wir bieten
            branchenübliche Sicherheitsvorkehrungen, können jedoch nicht für die
            absolute Sicherheit solcher Benutzerinhalte garantieren.
            Benutzerinhalte, die gegen diese Vereinbarung verstoßen oder die wir
            als schädlich für die Plattform erachten, können nach unserem
            Ermessen geändert, bearbeitet oder entfernt werden.
          </p>
          <p>
            Wenn Sie Benutzerinhalte an uns übermitteln, gewähren Sie Userbrain,
            seinen Partnern, verbundenen Unternehmen, Benutzern, Vertretern und
            Mitarbeitern eine nicht-exklusive, begrenzte, gebührenfreie,
            widerrufbare, weltweite, universelle, übertragbare und abtretbare
            Lizenz zur Anzeige, Verteilung, Speicherung, Ausstrahlung,
            Übertragung, Vervielfältigung, Änderung, Erstellung abgeleiteter
            Werke oder zur Verwendung und Wiederverwendung aller oder eines
            Teils Ihrer Benutzerinhalte zum Zweck der Bereitstellung von mit der
            Plattform verbundenen Dienstleistungen für die Dauer dieser
            Vereinbarung. Sie stimmen zu, dass diese Lizenz uns erlaubt, Ihre
            Benutzerinhalte mit unseren Kunden zu teilen. Darüber hinaus
            gewähren Sie Userbrain eine weltweite, unbefristete, unwiderrufliche
            und gebührenfreie Lizenz zur Nutzung und Einbindung von Vorschlägen,
            Verbesserungswünschen, Empfehlungen, Korrekturen oder sonstigem
            Feedback, das Sie uns in Bezug auf den Betrieb unserer Plattform zur
            Verfügung stellen, in die Plattform. Wir behalten uns das Recht vor,
            Benutzerinhalte zu entfernen, zu löschen, zu modifizieren, zu
            überprüfen, zu bearbeiten oder abzulehnen, aus welchem Grund auch
            immer und mit oder ohne Benachrichtigung an Sie.
          </p>

          <h2>12. Zusätzliche Erlaubnis für Benutzerinhalte</h2>
          <p>
            Wenn Sie Benutzerinhalte an uns übermitteln, stimmen Sie zu, dass
            alle Personen, deren Namen, Stimmen, Fotos, Abbildungen etc, die in
            den Benutzerinhalten verwendet werden oder verwendet wurden, der
            Verwendung durch den Benutzer zugestimmt haben, und dass Userbrain
            das Recht hat, alle Namen, Stimmen, Fotos, Abbildungen etc, die in
            den Benutzerinhalten enthalten sind, in Verbindung mit der
            Verwertung, Bearbeitung und Nutzung etc der im Rahmen dieser
            Vereinbarung gewährten Lizenz zu verwenden. Darüber hinaus
            versichern und garantieren Sie Folgendes: (1) Der Benutzer besitzt
            oder hat alle bereitgestellten Benutzerinhalte ordnungsgemäß
            lizenziert; (2) die Benutzerinhalte verletzen keine Urheberrechte,
            Urheberpersönlichkeitsrechte, Markenrechte oder andere Rechte an
            geistigem Eigentum und werden auch keine Rechte auf Privatsphäre
            oder Veröffentlichungsrechte oder sonstigen Rechte verletzen; (3)
            die angebotenen Nutzerinhalte verstoßen nicht gegen österreichische,
            internationale Gesetze oder Vereinbarungen mit Dritten; und (4) der
            Benutzer hat das uneingeschränkte Recht, Userbrain alle Rechte,
            Lizenzen und Privilegien zu gewähren, die Userbrain im Rahmen dieser
            Vereinbarung gewährt oder übertragen werden.
          </p>

          <h2>13. Überwachung von Benutzerinhalten</h2>
          <p>
            Userbrain hat das Recht, aber nicht die Pflicht, alle
            Benutzerinhalte auf der Plattform jederzeit zu überwachen, um die
            Einhaltung dieser Vereinbarung und aller von uns festgelegten
            Richtlinien zu überprüfen. Ohne Einschränkung des zuvor Gesagten hat
            Userbrain das Recht, aber nicht die Verpflichtung, Benutzerinhalte
            nach eigenem Ermessen zu entfernen. Beispielsweise können wir
            Benutzerinhalte entfernen, wenn wir glauben, dass Benutzerinhalte
            uns oder unseren (Geschäfts-)Interessen schaden könnten. Sofern
            nicht gesetzlich vorgeschrieben, sind wir nicht verpflichtet,
            Benutzerinhalte aufzubewahren oder Ihnen Kopien davon zur Verfügung
            zu stellen.
          </p>

          <h2>14. Plattformverfügbarkeit</h2>
          <p>
            Obwohl wir versuchen, Ihnen eine kontinuierliche Verfügbarkeit zu
            bieten, garantieren wir nicht, dass die Plattform durchgehend oder
            zu einem bestimmten Zeitpunkt immer verfügbar ist, funktioniert oder
            zugänglich ist. Insbesondere garantieren wir keine Betriebszeit oder
            spezifische Verfügbarkeit der Plattform. Sie stimmen zu und erkennen
            an, dass die Plattform einen Fernzugriff verwendet und
            möglicherweise nicht immer entweder 100% zuverlässig oder verfügbar
            ist. Wir können nicht garantieren, dass alles, was auf unserer
            Plattform gefunden wird, mit Ihren Geräten kompatibel ist und/oder
            der von Ihnen gewünschten Funktionalität entspricht oder Ihnen die
            gewünschten Ergebnisse liefert.
          </p>

          <h2>15. Änderung der Plattform</h2>
          <p>
            Wir behalten uns das Recht vor, die Plattform oder Teile davon
            jederzeit nach unserem Ermessen zu ändern, zu modifizieren, zu
            aktualisieren oder zu entfernen. Wir können solche Änderungen an
            unserer Plattform aus Sicherheitsgründen, geistigem Eigentum,
            rechtlichen Gründen oder aus verschiedenen anderen Gründen nach
            unserem Ermessen vornehmen, und wir sind nicht verpflichtet, solche
            Änderungen zu erklären oder Ihnen Zugang zu früheren Versionen
            unserer Plattform zu gewähren. Beispielsweise können wir Updates
            bereitstellen, um Sicherheitslücken zu beheben oder auf gesetzliche
            Anforderungen zu reagieren oder aus sonstigen in unserem Ermessen
            stehenden Gründen. Bitte beachten Sie, dass dies eine
            unverbindliches Beispiel ist, wie wir unsere Rechte gemäß diesem
            Punkt ausüben können, aber uns nach diesem Punkt nichts dazu
            verpflichtet, Maßnahmen zur Aktualisierung der Plattform aus
            Sicherheits-, rechtlichen oder anderen Gründen zu ergreifen.
          </p>

          <h2>16. Nichtumgehung</h2>
          <p>
            Der Benutzer erklärt sich damit einverstanden, die Plattform als
            ausschließliches Kommunikationsmittel zwischen ihm und allen
            Userbrain-Kunden zu verwenden. Sie dürfen nicht versuchen,
            Userbrain-Kunden Ihre Kontaktinformationen zur Verfügung zu stellen,
            und Sie stimmen zu, nicht zu versuchen, solche Userbrain-Kunden über
            soziale Medien oder andere Kommunikationsmittel zu kontaktieren.
            Dieser Abschnitt zur Nichtumgehung hindert Sie nicht daran, Produkte
            oder Dienstleistungen von Userbrain-Kunden zu verwenden oder zu
            kaufen. Sie erteilen Userbrain hiermit die Erlaubnis, die
            Kommunikation zwischen Ihnen und einem unserer Kunden auf der
            Plattform zu überprüfen, um die Einhaltung dieser Bestimmung zu
            überprüfen und durchzusetzen. Wenn Sie gegen diese Bestimmung
            verstoßen, können wir alle Zahlungen einbehalten und Ihr Konto nach
            unserem Ermessen kündigen.
          </p>

          <h2>17. Zahlungen / Verfall</h2>
          <p>
            Sobald ein Benutzer einen Test abgeschlossen hat, wird der Benutzer
            aufgefordert diesen Test auf die Plattform hochzuladen. Userbrain
            überprüft die eingereichten Benutzerinhalte, um sicherzustellen,
            dass der Test zufriedenstellend abgeschlossen wurde. Bitte beachten
            Sie, dass die Genehmigung für abgeschlossene Tests im alleinigen
            Ermessen von Userbrain liegt und jeder abgeschlossene Test im
            alleinigen Ermessen von Userbrain abgelehnt werden kann. Die
            Genehmigung kann auf einer Reihe von Faktoren basieren,
            einschließlich, aber nicht beschränkt auf Aufzeichnungsqualität,
            Übermittlung von Informationen, Nichtbeachtung der Testanweisungen
            und andere von Userbrain festgelegte Kriterien. Nach der Genehmigung
            wird Userbrain dem Konto des Benutzers eine Gutschrift abzüglich
            etwaiger Servicegebühren, Bearbeitungsgebühren, Steuern und
            sonstiger Abzüge („Userbrain-Gebühr“) an den Benutzer ausstellen.
            Der für jeden abgeschlossenen Test gutgeschriebene Betrag kann auf
            der Plattform oder dem Konto-Dashboard des Benutzers eingesehen
            werden. Userbrain behält sich das Recht vor, Guthaben für
            abgeschlossene Tests oder Userbrain-Gebühren jederzeit zu ändern und
            zu anzupassen. Wenn Userbrain eine solche Userbrain-Gebühr ändert,
            wird es den Benutzer kontaktieren und den Benutzer benachrichtigen,
            bevor eine solche Erhöhung der Userbrain-Gebühren eingeleitet wird.
            Im Falle einer Streitigkeit zwischen Benutzer und Userbrain stimmt
            der Benutzer zu, dass Userbrain alle Gelder zurückhalten kann, bis
            eine solche Streitigkeit beigelegt ist. Damit der Benutzer Zahlungen
            abheben kann, kann Userbrain verlangen, dass vor der Auszahlung ein
            Mindestschwellenwert innerhalb des Benutzerkontos erreicht wird
            sowie eingetragene Daten wie insbesondere Name und Anschrift auf
            Plausibilität geprüft werden. Wenn eine Auszahlungsanfrage gestellt
            wird, kann die Bearbeitung einer solchen Auszahlung einige Zeit in
            Anspruch nehmen, wobei es im Allgemeinen innerhalb einer Woche auf
            das Konto des Benutzers überwiesen wird.
          </p>
          <p>
            Solange der Benutzer die Auszahlung des Entgelts für abgeschlossene
            Tests nicht anfordert, wird es auf der Plattform oder dem
            Konto-Dashboard des Benutzers als „Guthaben“ verbucht. Der Benutzer
            kann sein Guthaben jederzeit einsehen und die Auszahlung verlangen.
          </p>
          <p>
            <strong>
              Der Benutzer hat{" "}
              <span style={{ textDecoration: "underline" }}>ein (1) Jahr</span>{" "}
              ab Gutbuchung eines Entgelts als Guthaben auf dem Konto-Dashboard
              des Benutzers Zeit, die Auszahlung des Guthabens an ihn zu
              verlangen.{" "}
              <span style={{ textDecoration: "underline" }}>
                Danach verfällt das Guthaben unwiderruflich.
              </span>
            </strong>
          </p>
          <p>
            <strong>
              Verlangt der Benutzer also die Auszahlung eines Guthabens nicht
              vor Ablauf eines Jahres, nachdem es erstmals auf seinem Konto
              gutgebucht wurde, verfällt das Guthaben bzw der Anspruch auf
              Auszahlung des Entgelts.
            </strong>
          </p>
          <p>
            <strong>
              Der Benutzer wird von Userbrain sechs (6) Monate sowie vierzehn
              (14) Tage vor dem drohenden Verfall mittels E-Mail und/oder SMS
              auf die Möglichkeit der Auszahlung sowie den sonst drohenden
              Verfall hingewiesen. Unterbleibt eine solche Verständigung, kann
              sich Userbrain auf diese Verfallsbestimmung nicht berufen.
              Unbenommen bleibt Userbrain aber eine Berufung auf die
              gesetzlichen Verjährungs- und Verfallsregeln.
            </strong>
          </p>

          <h2>18. Steuern</h2>
          <p>
            Damit Userbrain die Steuergesetze einhalten kann, kann es sein, dass
            Benutzer verpflichtet sind, die von Userbrain geforderten
            Steuerunterlagen vorzulegen. Der Benutzer erklärt sich damit
            einverstanden, allen Anfragen zur Vorlage von Steuerdokumenten
            nachzukommen, wie von Userbrain verlangt, und wird Userbrain bei
            allen Anfragen im Zusammenhang mit der Einhaltung der
            Steuervorschriften angemessen unterstützen. Der Benutzer weiß und
            erklärt, dass er seine Tätigkeit betreffend für alle steuerlichen
            Fragen sowie Handlungen, wie insbesondere die Berechnung und Abfuhr
            der von ihm zu entrichtenden Steuern, Gebühren, Abgaben etc,
            selbstverantwortlich ist.
          </p>

          <h2>19. Kontosperre / Vertragsauflösung</h2>
          <p>
            Aus wichtigen Gründen kann Userbrain das Konto eines Benutzers
            vorübergehend sperren („Hold“) oder endgültig sperren (vorzeitige
            Vertragsauflösung aus wichtigem Grund).
          </p>
          <p>
            Gründe, warum wir das Konto des Benutzers vorübergehend sperren
            können, sind unter anderem die folgenden: (1) wenn wir Grund zu der
            Annahme haben, dass Sie oder Ihre Handlungen gegen diese
            Vereinbarung verstoßen haben, unserem Geschäft schaden können,
            irreführend oder irreführend sind, rechtswidrig ist oder einem
            Dritten Schaden zugefügt hat oder in ein vertragliches Recht Dritter
            eingreift; (2) auf Anfrage unserer Zahlungsabwickler; oder (3) falls
            erforderlich, um einem Gerichtsbeschluss, einer Vorladung, einer
            gerichtlichen Verfügung, einer einstweiligen Verfügung nachzukommen
            oder anderweitig nach den geltenden Gesetzen und Vorschriften
            erforderlich ist.
          </p>
          <p>
            Ein wichtiger Grund, der uns zur endgültigen Kontosperre und
            sofortigen Vertragsauflösung berechtigt, ohne dass wir auf die
            Geltendmachung von Schadenersatzansprüchen oder allfällige sonstige
            Ansprüche gegen den Benutzer verzichten, stellt jeder Verstoß des
            Benutzers gegen seine wesentlichen Pflichten dieser Vereinbarung
            (insbesondere Punkte 2. bis 5. sowie 6. bis 9. sowie 11.) dar. Ein
            solcher Verstoß führt zum Verlust des Anspruchs des Benutzers auf
            Zahlung (Punkt 18.). Im Falle einer Kündigung werden wir uns
            bemühen, Ihnen rechtzeitig eine Erklärung abzugeben; dazu sind wir
            jedoch nicht verpflichtet.
          </p>
          <p>
            Userbrain entscheidet fallbezogen nach Schwere des Verstoßes und
            Zumutbarkeit der weiteren Aufrechterhaltung des
            Vertragsverhältnisses, ob eine vorübergehende oder endgültige Sperre
            samt Vertragsauflösung erfolgt. In jedem Fall bleibt der Benutzer
            zur Einhaltung jener Bestimmungen verpflichtet, an deren Einhaltung
            wir auch noch nach Vertragsdauer ein legitimes Interesse haben (wie
            insbesondere die Verpflichtung zur Geheimhaltung laut Punkt 8.) Wenn
            Sie Fragen zu einer Sperre haben, die wir möglicherweise auf Ihr
            Konto gesetzt haben, oder Informationen zum Auflösen der Sperre
            benötigen, wenden Sie sich bitte an uns.
          </p>

          <h2>20. Inaktivität</h2>
          <p>
            Wenn der Benutzer über einen längeren Zeitraum nicht auf sein Konto
            zugegriffen hat, kann Userbrain das Konto des Benutzers sperren,
            deaktivieren oder anderweitig archivieren („inaktives Konto“). Wenn
            der Benutzer das inaktive Konto reaktivieren möchte, muss er
            Userbrain unter{" "}
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>{" "}
            kontaktieren . Wenn ein Benutzer ein inaktives Konto hat, kann
            Userbrain das Konto nach eigenem Ermessen führen. Alle inaktiven
            Konten können jedoch nach alleinigem Ermessen von Userbrain entfernt
            oder gelöscht werden. Wenn der Benutzer über ein inaktives Konto
            verfügt, haftet Userbrain gegenüber dem Benutzer nicht für den
            Verlust von Materialien aus einem solchen inaktiven Konto und ist
            nicht verpflichtet, ein inaktives Konto zu unterhalten
          </p>

          <h2>21. Geistiges Eigentum</h2>
          <p>
            Der Name „Userbrain“, die Userbrain-Plattform sowie das Design der
            Userbrain-Plattform und alle Texte, Schriften, Bilder, Vorlagen,
            Skripte, Grafiken, interaktiven Funktionen und alle darin
            enthaltenen Marken oder Logos („Marken“) stehen im Eigentum von
            Userbrain oder sind an Userbrain lizenziert, unterliegen dem
            Urheberrecht und anderen gewerblichen Schutzrechten nach in- und
            ausländischen Gesetzen und internationalen Konventionen. Userbrain
            behält sich alle nicht ausdrücklich an Benutzern gewährte Rechte an
            der Plattform vor. Sie erklären sich damit einverstanden, nichts auf
            der Plattform zu verwenden, zu kopieren oder zu verbreiten, es sei
            denn, wir haben eine ausdrückliche schriftliche Genehmigung erteilt.
          </p>

          <h2>22. Einreichung von Ideen</h2>
          <p>
            Userbrain oder seine Mitarbeiter akzeptieren oder berücksichtigen
            keine unaufgefordert eingesandten Ideen, einschließlich aber nicht
            beschränkt auf Ideen in Bezug auf Prozesse, Technologien,
            Produktverbesserungen oder Produktnamen. Bitte übermitteln Sie
            Userbrain nicht unaufgefordert Ideen, Inhalte, Grafiken, Vorschläge
            oder andere Werke („Einsendungen“) in irgendeiner Form ein. Der
            Zweck dieses Vereinbarungspunktes besteht darin, potenzielle
            Missverständnisse oder Streitigkeiten zu vermeiden, soweit Produkte
            von Userbrain den von Ihnen an Userbrain übermittelten Ideen ähneln
            würden. In diesen Fall, also wenn Sie trotz unserer Aufforderung,
            uns keine derartigen Inhalte zu übermitteln, dennoch solche
            Einsendungen an uns übermitteln, dann gilt Folgendes: (1) Ihre
            Einsendungen und deren Inhalt werden automatisch Alleineigentum von
            Userbrain samt allen sonstigen Rechten (der Einsender verliert
            sämtliche Rechte, diese gehen vollständig auf Userbrain über), ohne
            dass Sie dafür entschädigt werden; (2) Userbrain darf die
            Einsendungen und deren Inhalte für jeden Zweck und auf jede Weise
            verwenden oder weitergeben; (3) Es besteht keine Verpflichtung für
            Userbrain, die Einsendungen zu überprüfen; und (4) es besteht keine
            Verpflichtung, die Einsendungen vertraulich zu behandeln.
          </p>

          <h2>23. Haftungsausschluss</h2>
          <p>
            <strong>
              Die Plattform und alle Userbrain-Dienste werden "wie besehen",
              "wie verfügbar" und "mit allen Fehlern" bereitgestellt. Soweit
              gesetzlich zulässig, geben weder Userbrain noch unsere
              Angestellten, Manager, Geschäftsführer oder Vertreter
              Zusicherungen, Garantien oder Empfehlungen jeglicher Art ab,
              insbesondere aber nicht ausschließlich: (1) zu der Plattform oder
              jeglichen Userbrain-Diensten; (2) zu den auf der Plattform
              bereitgestellten Informationen oder Inhalten; (3) zur Sicherheit
              oder Beständigkeit der Userbrain-Dienste oder der Plattform; (4)
              zur Sicherheit im Zusammenhang mit der Übermittlung von
              Informationen an Userbrain oder Dritte. Zusätzlich lehnen wir alle
              ausdrücklichen oder stillschweigenden Gewährleistungen oder
              Haftungen ab, einschließlich, aber nicht beschränkt auf die die
              Eignung für einen bestimmten Zweck, Benutzer Definiertheit,
              Störungsfreiheit, Nichtverletzung Rechte Dritter,
              Systemintegration und Virenfreiheit. Userbrain übernimmt keine
              Zusicherung oder Gewährleistung, dass die Plattform fehlerfrei
              oder unterbrechungsfrei ist; oder dass Defekte behoben werden;
              oder dass die Plattform oder der Server, der die Plattform zur
              Verfügung stellt, frei von schädlichen Komponenten sind. Userbrain
              übernimmt keine Zusicherungen oder Gewährleistung, dass die
              Informationen (einschließlich Anweisungen oder Informationen
              Dritter) auf der Plattform genau, vollständig oder nützlich sind.
              Userbrain übernimmt keine Gewährleistung, dass Ihre Nutzung der
              Plattform in einer bestimmten Gerichtsbarkeit rechtmäßig ist, und
              Userbrain schließt eine daraus allenfalls resultierende Haftung
              aus. Userbrain übernimmt keine Gewährleistung oder Haftung für die
              Genauigkeit, Vollständigkeit, Aktualität oder Nützlichkeit etc.
            </strong>
          </p>
          <p>
            <strong>
              In keinem Fall sind Userbrain, seine Angestellten, Manager,
              Geschäftsführer oder Vertreter Ihnen gegenüber für direkte oder
              indirekte Schäden oder Neben- oder Folgeschäden oder sonstige
              Nachteile aller Art haftbar, weder nach Vertrag noch nach Gesetz
              noch für den Fall, dass Userbrain über die Möglichkeit solcher
              Schäden informiert wird. Die vorstehende Haftungsbeschränkung gilt
              soweit gesetzlich zulässig in der jeweils anwendbaren
              Gerichtsbarkeit. In einigen Gerichtsbarkeiten ist die Beschränkung
              oder der Ausschluss der Haftung für bestimmte Schäden nicht
              zulässig. Soweit dies unzulässig ist, schließen wir in diesen
              Gerichtsbarkeiten die Haftung nicht aus für: (1) Tod oder
              Personenverletzung, die durch Userbrain oder einer seiner
              Angestellten, Manager, Geschäftsführer oder Vertreter verursacht
              wurden; (2) vorsätzliche Schädigung; (3) jegliche Haftung, deren
              Ausschluss jetzt oder in der Zukunft nicht mit dem Gesetz der
              anzuwendenden Gerichtsbarkeit übereinstimmt. Es wird aber
              vereinbart, dass all jene Ausschlüsse, die zulässig sind, von der
              Unzulässigkeit einzelner Ausschlüsse unberührt bleiben soll; alle
              zulässigen Haftungsausschlüsse bleiben demnach bei Unzulässigkeit
              bloß einzelner Ausschlüsse erhalten. Jedenfalls wird auch im Falle
              der Unzulässigkeit eines Haftungsausschlusses eine betragliche
              Haftungsbeschränkung von Userbrain derart vereinbart, dass die
              Haftung von Userbrain nicht die Beträge übersteigt, welche
              Userbrain in den vergangenen (6) Monaten an Entgelt an den
              (geschädigten) Tester / Benutzer bezahlt hat.
            </strong>
          </p>

          <h2>24. Entschädigung</h2>
          <p>
            Sie erklären sich damit einverstanden, Userbrain, seine
            Angestellten, Manager, Geschäftsführer, verbundenen Unternehmen und
            Vertreter von und gegen alle Ansprüche, Schäden, Verpflichtungen,
            Verluste, Verbindlichkeiten, Kosten oder Schulden und Ausgaben
            (einschließlich Verfahrens- und Anwaltskosten) aus allen
            Vertragsverletzungen und rechtswidrigen Handlungen im Zusammenhang
            mit diesem Vertrag, insbesondere aus jenen, die durch
          </p>
          <ul>
            <li>
              Ihre Nutzung und Ihr Zugriff auf die Userbrain-Plattform und die
              Userbrain-Dienste oder
            </li>
            <li>
              Ihre Verletzung einer Bestimmung dieser Vereinbarung, insbesondere
              der Geheimhaltungsverpflichtung oder
            </li>
            <li>
              Ihre Verletzung von Rechten Dritter, einschließlich, aber nicht
              beschränkt auf Urheberrechte, Eigentums- oder Vertragsrechte
            </li>
          </ul>
          <p>entstehen, schad- und klaglos zu halten.</p>
          <p>
            Diese Verteidigungs- und Freistellungsverpflichtung gilt über die
            Dauer dieser Vereinbarung und der Nutzung der Userbrain-Plattform
            hinaus. Sie stimmen auch zu, dass Sie verpflichtet sind, uns auf
            Ihre Kosten und Ihr Risiko auf unser Verlangen gegen solche
            Ansprüche zu verteidigen, und wir in solchen Fällen von Ihnen
            verlangen können, dass Sie die Kosten für unsere anwaltliche
            Vertretung tragen. Diese Verpflichtung erstreckt sich auch auf die
            Tragung aller Gerichtskosten, Gebühren, Strafen,
            Zahlungsverpflichtungen aus Gerichts- und Behördenentscheidungen und
            aller sonstigen Auslagen. Im Falle eines Anspruchs, wie er in diesem
            Punkt beschrieben ist, können wir uns entscheiden, einen Vergleich
            mit der Partei/den Parteien, die den Anspruch erheben, vorzunehmen,
            und Sie haften für die daraus resultierenden Folgen und Kosten, als
            ob wir ein Gerichtsverfahren eingeleitet hätten.
          </p>

          <h2>25. Urheberrechte</h2>
          <p>
            Wir nehmen Urheberrechtsverletzungen sehr ernst. Wenn Sie der
            Meinung sind, dass Ihre Inhalte verletzt wurden, senden Sie uns
            bitte eine Nachricht, die Folgendes enthält:
          </p>
          <ul>
            <li>Ihren Namen</li>
            <li>
              Der Name der Partei, deren Urheberrecht verletzt wurde, falls
              dieser von Ihrem Namen abweicht.
            </li>
            <li>
              Der Name und die Beschreibung des Werkes, gegen das verstoßen
              wird.
            </li>
            <li>Der Ort auf unserer Plattform der verletzenden Kopie.</li>
            <li>
              Eine Erklärung, dass Sie in gutem Glauben der Ansicht sind, dass
              die Nutzung des oben beschriebenen urheberrechtlich geschützten
              Werks nicht vom Urheberrechtsinhaber (oder von einem Dritten, der
              gesetzlich dazu berechtigt ist, im Namen des
              Urheberrechtsinhabers) genehmigt wurde und anderweitig nicht
              gestattet ist.
            </li>
            <li>
              Eine Erklärung, dass Sie eidesstattlich erklären, dass die in
              dieser Benachrichtigung enthaltenen Informationen richtig sind und
              dass Sie der Urheberrechtsinhaber sind oder ein ausschließliches
              gesetzliches Recht haben, ein Verletzungsverfahren in Bezug auf
              die Verwendung einzuleiten. Sie müssen diese Benachrichtigung
              unterschreiben und an unseren Copyright-Agenten senden:
              Copyright-Agent von Userbrain,{" "}
              <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>
            </li>
          </ul>

          <h3>Gegendarstellung</h3>
          <p>
            Für den Fall, dass Sie von Userbrain eine Benachrichtigung erhalten,
            dass von Ihnen gepostete Inhalte urheberrechtlich geschützt wurden,
            können Sie gemäß DMCA eine Gegendarstellung einreichen. Ihre
            Gegendarstellung muss Folgendes enthalten:
          </p>
          <ul>
            <li>
              Ihren Namen, Adresse, E-Mail und physische oder elektronische
              Unterschrift.
            </li>
            <li>Die Referenznummer der Benachrichtigung (falls zutreffend).</li>
            <li>
              Identifizierung des Materials und seines Standorts, bevor es
              entfernt wurde.
            </li>
            <li>
              Eine eidesstattliche Erklärung, dass das Material aus Versehen
              oder durch falsche Identifizierung entfernt wurde.
            </li>
            <li>
              Ihre Zustimmung zur Zuständigkeit eines Bundesgerichts in dem
              Bezirk, in dem Sie wohnen (wenn Sie sich in den USA befinden) oder
              Ihre Zustimmung zur Zuständigkeit eines Bundesgerichts in dem
              Bezirk, in dem sich Ihr Verkäufer befindet (wenn Sie sich nicht in
              den USA befinden). ).
            </li>
            <li>
              Ihre Zustimmung, die Prozesszustellung der Partei zu akzeptieren,
              die die Deaktivierungsmitteilung eingereicht hat.
            </li>
          </ul>
          <p>
            Bitte beachten Sie, dass wir bezüglich Ihrer Gegendarstellung keine
            Maßnahmen ergreifen dürfen, es sei denn, Ihre Mitteilung entspricht
            strikt den vorstehenden Anforderungen. Bitte senden Sie diese
            Gegendarstellung gemäß den Anweisungen zur Deaktivierungsanfrage
            oben.
          </p>

          <h2>26. Rechtswahl</h2>
          <p>
            Dieser Vertrag unterliegt dem in Österreich geltenden Recht. Angebot
            und Annahme dieses Vertrages gelten als in Österreich erfolgt.
          </p>

          <h2>27. Gerichtsstand</h2>
          <p>
            Zur Entscheidung von Streitigkeiten aus und im Zusammenhang mit der
            vorliegenden Vereinbarung einschließlich der Frage des gültigen
            Zustandekommens, des Bestandes oder Nichtbestandes und einer
            allfälligen Anfechtung dieser Vereinbarung ist ausschließlich das
            sachlich zuständige Gericht in Graz zuständig.
          </p>

          <h2>28. Status des unabhängigen Auftragnehmers</h2>
          <p>
            Diese Vereinbarung ist nicht so auszulegen, dass zwischen dem Nutzer
            und Userbrain eine Assoziation, Partnerschaft, ein Joint Venture,
            ein Angestellter, ein Arbeitnehmer oder eine Agenturbeziehung
            entsteht. Der Benutzer übt seine Funktion eigenverantwortlich aus.
            Er unterliegt keinen Weisungen über die Art der Ausführung seiner
            Tätigkeiten, wie insbesondere hinsichtlich Arbeitszeit, Arbeitsort,
            arbeitsbezogenes Verhalten oder persönliche Arbeitspflicht. Sollte
            er sich durch geeignete Dritte vertreten lassen können, hat er dies
            mitzuteilen und alle Pflichten zu überbinden und bleibt in diesem
            Fall für die ordnungsgemäße Leistungserbringung verantwortlich und
            hält Userbrain schad- und klaglos. Der Benutzer verfügt über die
            erforderlichenfalls nach dem geltenden Recht seines gewöhnlichen
            Aufenthalts bzw Unternehmenssitzes erforderliche Voraussetzungen für
            die Erbringung der Tätigkeiten. Der Benutzer ist für die
            gesetzmäßige Abfuhr von Steuern für die Einkünfte aus dieser
            Vereinbarung sowie Sozialversicherungsabgaben selbst verantwortlich.
            Er hält die Userbrain diesbezüglich schad- und klaglos. Der Benutzer
            hat keine Befugnis (und darf sich nicht als bevollmächtigt
            ausgeben), Userbrain in irgendeiner Art zu verpflichten oder zu
            binden, und der Benutzer darf ohne vorherige schriftliche Zustimmung
            von Userbrain keine Vereinbarungen oder Zusicherungen im Namen von
            Userbrain treffen. Der Benutzer ist für alle Ansprüche, Forderungen,
            Strafen, Klagen etc im Zusammenhang mit dieser Bestimmung selbst
            verantwortlich und hält Userbrain schad- und klaglos, einschließlich
            solcher Ansprüche, die der Benutzer oder ein Dritter in Bezug auf
            Steuern oder Abgaben geltend macht, einschließlich Zinsen, Strafen
            und aller sonstigen Forderungen.
          </p>

          <h2>29. Salvatorische Klausel</h2>
          <p>
            Sollte sich herausstellen, dass eine Bestimmung dieser Vereinbarung
            rechtswidrig ist, einer anderen Bestimmung der Vereinbarung
            widerspricht oder anderweitig nicht durchsetzbar ist, bleibt die
            Vereinbarung in Kraft, als ob sie abgeschlossen worden wäre, ohne
            dass diese undurchsetzbare Bestimmung darin enthalten wäre.
          </p>
          <p>
            Wenn zwei oder mehr Bestimmungen dieser Vereinbarung oder einer
            anderen Vereinbarung, die Sie möglicherweise mit Userbrain
            abgeschlossen haben, im Widerspruch zu der Funktionsweise des
            jeweils anderen stehen, hat Userbrain das alleinige Recht zu wählen,
            welche Bestimmung in Kraft bleibt.
          </p>

          <h2>30. Kein Verzicht auf die Durchsetzung / durch Schweigen</h2>
          <p>
            Wir behalten uns alle Rechte vor, die uns gemäß dieser Vereinbarung
            sowie gemäß den Bestimmungen aller anwendbaren Gesetze zustehen.
            Eine allfällige Nichtdurchsetzung einer bestimmten gesetzlichen
            Bestimmung oder Bestimmungen dieser Vereinbarung ist nicht als
            Verzicht von Userbrain auszulegen, diese Bestimmung unter denselben
            oder anderen Umständen zu einem beliebigen Zeitpunkt in der Zukunft
            durchzusetzen. Dasselbe gilt für Schweigen / Untätigkeit seitens
            Userbrain, das nicht als Zustimmung oder Rechtsaufgabe zu werten
            ist.
          </p>

          <h2>31. Übertragung von Rechten / Pflichten</h2>
          <p>
            Sie dürfen Ihre Rechte und/oder Pflichten aus dieser Vereinbarung
            ohne unsere vorherige schriftliche Zustimmung oder soweit diese
            Vereinbarung vereinzelt nichts anderes spezielleres bestimmt, nicht
            an eine andere Person abtreten oder übertragen. Wir können unsere
            Rechte und/oder Pflichten aus dieser Vereinbarung nach eigenem
            Ermessen an eine andere Person abtreten oder übertagen.{" "}
          </p>

          <h2>32. Kündigung durch den Benutzer</h2>
          <p>
            Sie können Ihr Konto jederzeit über Ihr Userbrain-Dashboard oder
            mittels Nachricht an{" "}
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>{" "}
            kündigen.{" "}
            <strong>
              Bitte beachten Sie, dass nach der Kündigung Ihres Kontos der
              Zugriff auf Teile unserer Plattform sofort gesperrt und auf alle
              Benutzerinhalte sofort nicht mehr zugegriffen werden kann.
            </strong>{" "}
            Der Vertrag wird mit der Kündigung mit sofortiger Wirkung
            wechselseitig aufgehoben.{" "}
            <strong>
              Der Benutzer erklärt mit seiner Kündigung, keine Rechte oder
              Ansprüche gegenüber Userbrain mehr zu haben.
            </strong>{" "}
            Der Benutzer bleibt aber zur Einhaltung aller Teile dieser
            Vereinbarung, von denen vernünftigerweise angenommen werden kann,
            dass sie die Gültigkeit der Vereinbarung überdauern, auch nach der
            Beendigung der Vereinbarung in vollem Umfang zur Einhaltung
            verpflichtet und an diese Bestimmungen gebunden, insbesondere aber
            nicht beschränkt auf Haftungsbeschränkungen, Zahlungen,
            Verschwiegenheitsverpflichtung und Entschädigung.
          </p>

          <h2>33. Änderungen der Vereinbarung</h2>
          <p>
            Diese Vereinbarung zusammen mit der von Userbrain bereitgestellten
            Datenschutzrichtlinie stellt die vollständige und ausschließliche
            Vereinbarung zwischen den Parteien in Bezug auf den hierin
            enthaltenen Gegenstand dar und ersetzt alle vorherigen oder
            gleichzeitigen schriftlichen oder mündlichen Vereinbarungen oder
            Vereinbarungen in Bezug auf ihren Gegenstand. Jede vom Benutzer
            gewünschte Änderung oder Ergänzung einer Bestimmung dieser
            Vereinbarung ist nur wirksam, wenn sie schriftlich erfolgt und von
            einem ordnungsgemäß bevollmächtigten Vertreter jeder Partei
            unterzeichnet wird, also mit Userbrain explizit, schriftlich und
            firmenmäßig vereinbart wurde. Wenn diese Vereinbarung im Widerspruch
            zu anderen auf unserer Website aufgeführten Dokumentation steht
            (ausgenommen die Datenschutzrichtlinie und Cookie Policy), hat diese
            Vereinbarung Vorrang.
          </p>
          <p>
            Wir können diese Vereinbarung jederzeit ändern. Wenn wir diese
            Vereinbarung ändern, aktualisieren wir diese Seite und geben das
            Datum der letzten Änderung an. Wir informieren Sie vor der Änderung
            über die Änderung mittels E-Mails sowie über Ihre
            Reaktionsmöglichkeiten und deren Folgen: Die Änderung tritt 30 Tage
            nach Versendung der Verständigung über die Änderung in Kraft, es sei
            denn, es erfolgt vor Ablauf dieser Frist ein schriftlicher
            Widerspruch, was zur Vertragsauflösung führt. Wenn Sie somit nicht
            rechtzeitig bzw formgerecht widersprechen, stimmen Sie der Änderung
            zu. Wenn Sie rechtzeitig und formgerecht widersprechen, gilt der
            Widerspruch als Kündigung (Punkt 34.).
          </p>

          <h2>34. Elektronische Kommunikation</h2>
          <p>
            Die Kommunikation zwischen Ihnen und Userbrain erfolgt auf
            elektronischem Weg, unabhängig davon, ob Sie die Plattform besuchen
            oder Userbrain-E-Mails senden (
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>),
            oder ob Userbrain Mitteilungen auf der Plattform veröffentlicht oder
            mit Ihnen über mobile Benachrichtigungen oder E-Mail kommuniziert.
            Für vertragliche Zwecke stimmen Sie (1) zu, Mitteilungen von
            Userbrain in elektronischer Form zu erhalten; und (2) stimmen zu,
            dass alle Bedingungen, Bedingungen, Vereinbarungen, Mitteilungen,
            Offenlegungen und andere Mitteilungen, die Userbrain Ihnen
            elektronisch zur Verfügung stellt, alle rechtlichen Anforderungen
            erfüllen, die solche Mitteilungen erfüllen würden, wenn sie
            schriftlich erfolgen würden. Das Vorstehende hat keinen Einfluss auf
            Ihre gesetzlichen Rechte.
          </p>

          <h2>35. Plattform und Kommunikation</h2>
          <p>
            Bei Fragen, Problemen oder Problemen beim Zugriff auf oder bei der
            Nutzung der Plattform kontaktieren Sie uns bitte über die von
            Userbrain bereitgestellten Kanäle. Sie erklären sich damit
            einverstanden, dass Sie ausschließlich die von der Plattform oder
            von Userbrain genehmigten Kanäle für alle Mitteilungen im
            Zusammenhang mit Ihrer Nutzung der Plattform oder Problemen mit
            dieser Vereinbarung verwenden. Bitte kontaktieren Sie uns unter{" "}
            <a href={"mailto:tester@userbrain.com"}>tester@userbrain.com</a>{" "}
            oder unter
          </p>
          <p>
            Userbrain GmbH
            <br />
            Frauengasse 7<br />
            8010 Graz, Austria
          </p>
        </HtmlContent>
      </div>
    </article>
  </Layout>
);

export default TesterTerms;
